<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <h4>{{ $t("PAGES.NORMS.heading") }}</h4>
      </v-col>
    </v-row>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <span class="mr-2">
          {{ $t("LABELS.year") }}<br />
          <v-select
            :items="years"
            v-model="year"
            dense
            outlined
            class="date-select"
          ></v-select>
        </span>
        <span>
          {{ $t("LABELS.month") }}<br />
          <v-select
            :items="months"
            v-model="month"
            dense
            outlined
            class="date-select"
          ></v-select>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <v-btn
          class="btn btn-success btn-elevate kt-login__btn-primary text-white"
          @click="downloadData()"
        >
          {{ $t("BUTTON.EXPORT") }}
        </v-btn>
      </div>
    </div>

    <h3
      v-if="!isLoadingStats && stats && !stats.dates"
      class="d-flex justify-content-center mb-5"
    >
      {{ $t("A.NO_DATA") }}
    </h3>

    <div
      v-if="!isLoadingStats && stats && stats.dates"
      class="table-responsive"
    >
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td rowspan="3"></td>
            <td class="center bold" rowspan="3">{{ $t("LABELS.date") }}</td>
            <td
              v-for="(operator, operatorName) in stats.stats"
              :key="operatorName"
              class="center bold"
              :colspan="operator.maxNormsCount"
            >
              {{ operatorName }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(operator, key) in stats.stats"
              :key="key"
              class="center bold"
              :colspan="operator.maxNormsCount"
            >
              {{ $t("LABELS.monthAverage") }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(operator, key) in stats.stats"
              :key="key"
              class="center bold"
              :colspan="operator.maxNormsCount"
            >
              {{ operator.monthAverage }}
            </td>
          </tr>
          <template v-for="(date, key) in stats.dates">
            <div :key="key" style="display: contents">
              <tr>
                <td class="bold">{{ $t("LABELS.norm") }}</td>
                <td class="center bold" rowspan="3">{{ date }}</td>
                <template v-for="(operator, key) in stats.stats">
                  <div :key="key" style="display: contents">
                    <td
                      v-for="(norm, key) in operator.byDates[date].norms"
                      :key="key"
                      class="center bold green"
                    >
                      {{ norm.norm }}
                    </td>
                  </div>
                </template>
              </tr>
              <tr>
                <td class="bold">{{ $t("LABELS.made") }}</td>
                <template v-for="(operator, key) in stats.stats">
                  <div :key="key" style="display: contents">
                    <td
                      v-for="(norm, key) in operator.byDates[date].norms"
                      :key="key"
                      class="center bold yellow"
                    >
                      {{ norm.made }}
                    </td>
                  </div>
                </template>
              </tr>
              <tr>
                <td class="bold">{{ $t("LABELS.result") }}</td>
                <td
                  v-for="(operator, key) in stats.stats"
                  :key="key"
                  class="center bold blue"
                  :colspan="operator.maxNormsCount"
                >
                  {{ operator.byDates[date].dayAverage }}
                </td>
              </tr>
            </div>
          </template>
        </tbody>
      </table>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { API_URL } from "@/common/config";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_NORMS_STATS, DOWNLOAD_STATS } from "@/store/statistics.module";

export default {
  data() {
    return {
      year: moment().year(),
      month: 1,
      stats: null,
    };
  },

  computed: {
    ...mapGetters(["isLoadingStats"]),

    years() {
      let years = [];

      for (let year = 2021; year <= moment().year(); year++) {
        years.push(year);
      }

      return years;
    },

    months() {
      let months = [];

      for (let month = 1; month <= 12; month++) {
        months.push({
          text: this.$t(`MONTHS.${month}`),
          value: month,
        });
      }

      return months;
    },
  },

  mounted() {
    let vm = this;

    vm.$store.dispatch(SET_BREADCRUMB, [{ title: "Норми" }]);

    vm.setYearAndMonth();
  },

  watch: {
    year() {
      this.fetchData();
    },

    month() {
      this.fetchData();
    },
  },

  methods: {
    setYearAndMonth() {
      let lastMonth = moment().subtract(1, "months");

      this.year = parseInt(lastMonth.format("Y"));
      this.month = parseInt(lastMonth.format("M"));

      this.fetchData();
    },

    fetchData() {
      let vm = this;

      vm.$store
        .dispatch(GET_NORMS_STATS, { year: vm.year, month: vm.month })
        .then((data) => {
          vm.stats = data.data;
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message,
            });
          }
        });
    },

    downloadData() {
      let vm = this;

      vm.$store
        .dispatch(DOWNLOAD_STATS)
        .then((data) => {
          window.open(
            `${API_URL}statistics/norms-export/${data.data.code}?year=${vm.year}&month=${vm.month}`
          );
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message,
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    },
  },
};
</script>

<style scoped>
td {
  min-width: 58px;
}

tr,
td {
  border-color: black;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
  vertical-align: middle;
}

.green {
  background-color: #92d050;
}

.yellow {
  background-color: #ffff00;
}

.blue {
  background-color: #bdd7ee;
}

.date-select {
  max-width: 150px;
}
</style>
